<template>
  <div
    class="col"
    id="ServiceSelector"
    style="display: flex; min-height: 70px !important; height: 100%"
  >
    <input
      ref="cb"
      type="checkbox"
      :value="value.id"
      class="checkbox"
      :id="'checkbox' + value.id"
      name="services"
    />
    <label
      :for="'checkbox' + value.id"
      :id="'label' + value.id"
      :class="'btn theme-' + theme + ' size-' + size + ' hover-' + hover"
      style="font-size: 100%; height: 100%"
      :style="
        lang == 'ar'
          ? 'letter-spacing: 0 !important;font-family: Noto Sans Arabic, sans-serif;font-size: 20px;'
          : ''
      "
    >
      <span
        style="
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          height: 100%;
        "
        >{{ name }}</span
      >
    </label>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "ServiceSelector",
  data: () => ({
    config: JSON.parse(JSON.parse(localStorage.getItem("kiosk")).kiosk.config),
    lang: localStorage.getItem("Language"),
    secondary: "#6c757d",
    popup_qte: {
      item: {},
      active: false,
      title: "",
      message: "",
      hint: "",
      type: "",
      confirmation: "",
      callback: "qteSelected",
    },
    qte: 1,
  }),
  props: {
    name: String,
    size: String,
    theme: String,
    hover: String,
    value: Object,
  },
  methods: {
    checkStateService(id, qte = 1) {
      this.qte = qte;

      $("#label" + id).css("border-left", "15px solid rgba(22, 214, 22, 0.7)");
      $(".qte" + id).show();
      $(".plus" + id).show();
    },
    unCheckStateService(id) {
      $("#label" + id).css("border-left", "15px solid " + this.secondary);
      $(".qte" + id).hide();
      $(".plus" + id).hide();
    },
    showPopupQte(item, type, title, message, hint, confirmation, callback) {
      this.popup_qte = {
        item: item,
        active: true,
        title: title,
        message: message,
        hint: hint,
        type: type,
        confirmation: confirmation,
        callback: callback,
      };
    },
    hidePopupQte() {
      this.popup_qte = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
    },
    openQtePopup(item) {
      if (localStorage.getItem("Language") == "en") {
        this.showPopupQte(
          item,
          "success",
          "Confirmation",
          "",
          "How many times do you want to do this service",
          "Confirm",
          this.hidePopupQte
        );
      } else if (localStorage.getItem("Language") == "ar") {
        this.showPopupQte(
          item,
          "success",
          "تأكيد",
          "",
          "كم مرة تريد القيام بهذه الخدمة",
          "تحديد",
          this.hidePopupQte
        );
      } else {
        this.showPopupQte(
          item,
          "success",
          "Confirmation",
          "",
          "Combien de fois voulez-vous faire ce service",
          "Confirmer",
          this.hidePopupQte
        );
      }
    },
    closeQtePopup() {
      this.hidePopupQte();
    },
  },
  mounted() {
    this.secondary = this.config.secondary ? this.config.secondary : "#6c757d";
  },
};
</script>
<style scoped>
</style>