<template>
  <div id="PopupSubServices" class="loading-wrapper" v-if="isActive">
    <div class="loading-popup">
      <div
        class="close"
        style="padding: 20px"
        @click="closepopupSubServices()"
      ></div>
      <form
        id="servicesFormSub"
        class="container services_container space-bottom"
        method="GET"
        action="Ticket"
        :style="
          kiosk_config.multi_language && kiosk_config.multi_language != 'false'
            ? ''
            : 'max-height: 65vh !important;'
        "
      >
        <div class="row">
          <div class="col-md-3" v-if="services.length == 1"></div>
          <div
            :class="
              services.length >= 6 ? 'col-md-12 service' : 'col-md-12 service'
            "
            v-for="(item, key) in services"
            :key="key"
          >
            <LbrxService
              ref="lbrxService"
              :name="item.title"
              size="small"
              theme="small"
              hover="false"
              :value="item"
              @checked="addSelection"
              @unchecked="removeSelection(item)"
            >
            </LbrxService>

            <!-- v-long-press="3000" @long-press-start="openQtePopup(item)" -->
          </div>
        </div>
      </form>
      <button class="btn-confirm" @click="closepopupSubServices()">
        {{ $t("Popup.Close") }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import LbrxService from "../../components/Services/ServiceSelector.vue";
let flagFr = require("@/assets/images/fr.png");
let flagTn = require("@/assets/images/tn.png");
let flagEn = require("@/assets/images/en.png");
// import $ from "jquery";
export default {
  name: "PopupTimeslots",
  data: () => ({
    qte: 1,
    selectedLanguage: {
      label: "",
      flag: null,
      code: "",
      selected: true,
    },
    lang: localStorage.getItem("Language"),
    languageSelectorVisible: false,
    languages: [
      { label: "English", flag: flagEn, code: "en", selected: false },
      { label: "Français", flag: flagFr, code: "fr", selected: false },
      { label: "العربية", flag: flagTn, code: "ar", selected: false },
    ],
  }),
  components: {
    LbrxService,
  },
  props: {
    active: Boolean,
    kiosk_config: Object,
    services: Array,
    selectedServices: Array,
    queueTargetedForServices: null,
  },
  methods: {
    // check if service is already taken
    checkState() {
      this.services.forEach((o) => {
        let check = this.selectedServices.some(
          (el) => el.service_id === o.service_id
        );
        if (check) {
          this.$refs.lbrxService[this.services.indexOf(o)].checkStateService(
            o.id,
            o.qte
          );
        }
      });
    },
    addSelection(item, qte) {
      this.$emit("addSelection", item, qte);
    },
    removeSelection(item) {
      this.$emit("removeSelection", item);
    },
    closepopupSubServices() {
      this.$emit("closepopupSubServices", false, false);
    },
    confirmSubServices() {
      this.$emit("confirmSubServices", false, true);
    },
    dayIsOpen(date) {
      if (this.available_dates.length) {
        let dayOfWeek = moment(date, "DD MMM YYYY").isoWeekday();
        return this.available_dates[dayOfWeek - 1].active;
      } else {
        return true;
      }
    },
    dayIsInHoliday(date) {
      if (this.holidays.length) {
        let day = moment(date, "DD MMM YYYY").format("YYYY-MM-DD");
        let isInHoliday = false;
        this.holidays.map((holiday) => {
          if (
            (holiday.department_id == null ||
              holiday.department_id == this.selected_department) &&
            (holiday.member_id == null ||
              holiday.member_id == this.selected_member)
          ) {
            isInHoliday =
              isInHoliday ||
              (day >= holiday.start_date && day <= holiday.end_date);
          }
        });
        return isInHoliday;
      } else {
        return false;
      }
    },
  },
  computed: {
    isActive() {
      return this.active;
    },
  },
};
</script>

<style lang="scss">
#PopupSubServices {
  .loading-wrapper {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .loading-popup {
    min-width: 60vw;
    max-height: 91vh;
    background: #fff;
    border-radius: 20px;
    height: auto !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: #264076;
  }
  .slots-member {
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    max-height: 60vh;
    overflow-y: auto !important;
  }
  .slots-member > * {
    flex: 0 0 30.3333%;
  }
  .slots {
    padding-left: 2.5vw;
    padding-top: 3vh;
    margin-left: 0px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-y: auto !important;
  }
  .slots > * {
    flex: 0 0 24.3333%;
  }
  .slots > *:nth-last-child(n + 19),
  *:nth-last-child(n + 19) ~ * {
    flex: 0 0 16.6%;
  }

  .badge-noSlots {
    position: fixed;
    margin-left: 40px;
    font-family: "Montserrat-regular";
    font-size: 26px;
    padding: 5px 15px;
    border-radius: 20px;
    width: 500px;
    margin-top: 25px;
    background-color: #e73d3d;
    color: var(--sidebar);
    border: 1px solid;
  }
  .slots .slot {
    padding: 14px 0px;
    background-color: var(--sidebar);
    margin-bottom: 5px;
    border-radius: 5px;
    transition: 0.5s;
    text-align: center;
    width: 7.4vw;
    height: 8vh;
    font-size: 3vh;
    font-family: "Montserrat";
    letter-spacing: 1px;
    font-weight: bold;
    cursor: pointer;
    color: var(--primary);
  }
  .close {
    float: right;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
  }
  .btn-confirm {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0px 0px 20px 20px;
    padding: 15px;
    width: 100%;
    border: none;
    background: var(--secondary);
    color: #fff;
    font-weight: 400;
    font-size: 22px;
  }
  .space-bottom {
    margin-bottom: 14vh;
  }
}
</style>